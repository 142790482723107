import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login/:infomsg?',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path:'/products/:type',
    name:'productvue',
    component: () => import('../views/Products.vue')
  }, 
  {
    path:'/productseditor/:type/:productid',
    name:'productseditor',
    component: () => import('../views/ProductsEditor.vue')
  },
  {
    path:'/introeditor/:type/:articlesi',
    name:'introeditor',
    component: () => import('../views/IntroEditor.vue')
  },
  {
    path:'/contact',
    name:'contact',
    component: () => import('../views/Contact.vue')
  },{
    path:'/abouteditor/:id',
    name:'abouteditor',
    component: () => import('../views/AboutEditor.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
