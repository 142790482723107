<template>
    <footer style="background-color: rgba(47,23,15,.9); color: white; padding: 0px 0; text-align: center;margin-top:30px;">
      <div style="max-width: 1200px; margin: 0 auto; padding: 20px;">
          <div style="display: flex; flex-wrap: wrap; justify-content: space-around;">
              <!-- Company Information -->
              <div style="flex: 1; min-width: 200px; margin: 10px;">
                  <h4 style="border-bottom: 1px solid #7f8c8d; padding-bottom: 10px;">À propos de nous</h4>
                  <p>Frésard Frères</p>
                  <p>Adresse : Chemin du Finage 9, 2350 Saignelégier, Suisse</p>
                  <p>Téléphone : +41 32 951 29 58</p>
                  <p>Email : info@fresardfreres.ch</p>
              </div>
              
              <!-- Social Media Links -->
              <div style="flex: 1; min-width: 200px; margin: 10px;">
                  <h4 style="border-bottom: 1px solid #7f8c8d; padding-bottom: 10px;">Suivez-nous</h4>
                  <p>
                      <a href="https://www.facebook.com/profile.php?id=61557849710190" style="color: white; margin-right: 10px; text-decoration: none;">Facebook</a>                      
                      <a href="https://instagram.com/fresard_freres2350" style="color: white; margin-right: 10px; text-decoration: none;">Instagram</a>
                      <a href="https://ch.linkedin.com/company/fr%C3%A9sard-fr%C3%A8res" style="color: white; margin-right: 10px; text-decoration: none;">LinkedIn</a>
                  </p>
              </div>
          </div>
          <div style="border-top: 1px solid #7f8c8d; margin-top: 20px; padding-top: 10px;">
              <p style="margin: 0;">&copy; 2024 Frésard Frères. Tous droits réservés.</p>
          </div>
            <div>
                <h2>Nos partenaires</h2>
                <span v-for="partener in parteners" :key="partener" class="m-2">
                    <a :href="partener.link"><img style="height: 64px;margin-right:10px;" :src="partener.logo" ></a>
                </span>
            </div>
      </div>
  </footer>
</template>
  
    <script>
        import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'
        import 'vue3-carousel/dist/carousel.css'
        export default {
            name: 'Footer',
            components:{
                Carousel,
                Slide,
                Pagination,
                Navigation
            },data(){
                return {
                    parteners:[
                        { logo:require('@/assets/velux-logo-pos.svg'),link:'https://www.velux.ch/' },
                        { logo:require('@/assets/logo_riwag.1fdec917.svg'),link:'https://www.riwag.ch/' },
                        { logo:require('@/assets/partner/ehret.png'),link:'https://www.ehret.com/'},
                        { logo:require('@/assets/partner/delaval.png'),link:'https://www.delaval.com/'},
                        { logo:require('@/assets/partner/novoferm_Logo_.svg'),link:'https://www.novoferm.ch/'},
                        { logo:require('@/assets/partner/aragon.png'),link:'https://www.aragon-bauelemente.ch/'},
                        { logo:require('@/assets/partner/logo_weinstock.svg'),link:'https://www.ideal-fensterbau.de/'},
                        { logo:require('@/assets/partner/pirnar-gmbh-logo-vector.png'),link:'https://www.pirnar.fr/'},
                        { logo:require('@/assets/partner/rollfix.png'),link:'https://www.rollfix.com/'},
                        { logo:require('@/assets/partner/HOPPE-Group-WBM-EN-04-2024-sRGB.svg'),link:'https://www.hoppe.com/'},
                        { logo:require('@/assets/partner/pruemlogo.png'),link:'https://www.tuer.de/'},
                        { logo:require('@/assets/partner/THU_Logo_Claim_RGB.jpg'),link:'https://thuering-ag.ch/'},
                        { logo:require('@/assets/partner/matsabag.png'),link:'https://www.matsabag.ch/'},
                        { logo:require('@/assets/partner/2560px-Sabag.svg.png'),link:'https://www.sabag.ch/'},
                        { logo:require('@/assets/partner/BN-Tortechnik-Logo.svg'),link:'https://www.tortechnik.com/'},
                        { logo:require('@/assets/partner/siegenia_logo.svg'),link:'https://shop.siegenia.ch/'},
                        { logo:require('@/assets/partner/deligno-logo.svg'),link:'https://deligno.ch/de/'},
                        { logo:require('@/assets/partner/groupecorbat-images.png'),link:'https://www.groupe-corbat.ch/'},
                        { logo:require('@/assets/partner/olwo-logo.png'),link:'https://www.olwo.ch/'},
                        { logo:require('@/assets/partner/dad6c8_3fd295988bf949cfad3a9fe02e09bf7b~mv2.png'),link:'https://www.quinca.ch/'},
                        { logo:require('@/assets/partner/immer-logo.svg'),link:'https://www.immerag.ch/'},
                        { logo:require('@/assets/partner/logo.8228e972.svg'),link:'https://www.sfs.ch/fr/'},
                        { logo:require('@/assets/partner/SIGA_Fentrim_Majvest700_2020.jpg'),link:'https://www.siga.swiss/'},
                        { logo:require('@/assets/partner/hager-zweitlogo-rgb-250px-2-1.png'),link:'https://hagerag.ch/'},
                        { logo:require('@/assets/partner/blum-logo.svg'),link:'https://www.blum.com/ch/'},
                        { logo:require('@/assets/partner/logo-Fermacell-new.svg'),link:'https://www.fermacell.ch/'},
                        { logo:require('@/assets/partner/James-Hardie-Brand-Logo_Free_White_def.svg'),link:'https://www.jameshardie.ch/'},
                        { logo:require('@/assets/partner/MicrosoftTeams-image (2).png'),link:'https://www.isover.ch/'},
                        { logo:require('@/assets/partner/logo-off-hoyez.png'),link:'https://www.hoyez.com/'},
                        { logo:require('@/assets/partner/rz_BAL_Logo_d_rgb.png'),link:'https://balteschwiler.ch/'},
                        { logo:require('@/assets/partner/leuba-hiag-logo-fr-1657005325.svg'),link:'https://www.leubahiag.ch/'},
                        { logo:require('@/assets/partner/stucky-logo.svg'),link:'https://www.stucky-ag.ch/'},
                        { logo:require('@/assets/partner/Logo-ws.png'),link:'https://black-milk.net/'},
                        { logo:require('@/assets/partner/engel-images.jpg'),link:'https://shop.engel.ch/'}
                    ]
                };
            }
        }
    </script>
  
  <style>
    footer {
        background-color: #FFF7EB;        
    }
  </style>