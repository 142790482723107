<template>
    <div class="container">          
        <Carousel :autoplay="5000" :wrapAround="true" :transition="500" >
            <Slide v-for="article in articles" :key="article.articlesi">                    
                    <div class="intro-text text-center bg-faded p-5 rounded w-100">
                        <img class="border-round img-fluid" style="max-height:450px;" :src="this.GBASEURLAPI+'/Files/getFileByUUID/'+article.relatedimages[0].name" alt="" >
                        <h1 class="section-heading mb-4">
                            <span class="section-heading-upper" >{{article.title}}</span>
                        </h1>
                        <span class="mb-3 text-block">
                            {{ article.text }}<br>
                            <button v-if="$cookies.get('token')" type="button" class="btn btn-success m-1"  @click="$router.push('/introeditor/intro/'+article.articlesi)" >Modifier</button>
                            <button v-if="$cookies.get('token')" type="button" class="btn btn-danger m-1"  @click="deleteproduct(article.articlesi)" >Supprimer</button>
                        </span>                        
                    </div>                                        
            </Slide>
            <template #addons>
                <Navigation />
                <Pagination />
            </template>
        </Carousel>
    </div>
</template>

<script>
import axios from 'axios'
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'

export default {
    components:{
        Carousel,
        Slide,
        Pagination,
        Navigation
    },data(){
        this.getarticles();
        return {
            articles:[]
        }
    },methods:{
        getarticles : function() {
            axios.get(this.GBASEURLAPI+"/Articles/GetArticles/intro").then((response)=>{                
                this.articles = response.data;
            }).catch((response)=>{
                console.log(response);
            });            
        },deleteproduct: function(articlesi){
            axios.get(this.GBASEURLAPI+"/Articles/DeleteArticles/"+articlesi).then((response)=>{                
                this.getarticles();
            }).catch((response)=>{
                console.log(response);
            }); 
        }
    }
}
</script>

<style>
    .text-block {
        white-space: pre-line;
    }
</style>