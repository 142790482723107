<template>
  <button v-if="$cookies.get('token')" type="button" class="btn btn-primary" @click="$router.push('/introeditor/intro/new')">Ajouter</button>
  <Article></Article>
  <hist aboutid="1"></hist>
</template>

<script>
// @ is an alias to /src
import Article from '@/components/Intro.vue'
import hist from '@/components/About.vue'

export default {
  name: 'Home',
  components: {
    Article,
    hist
  }
}
</script>
