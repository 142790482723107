<template>  
    <h1 class="site-heading text-center text-white d-none d-lg-block mt-0 mb-3">
        <img src="./assets/logo.png" width="30%">
    </h1>
		
		<h1 class="site-heading text-center text-white d-lg-none mt-0 mb-3">
        <img src="./assets/logo.png" width="80%">
    </h1>
    
    <nav class="navbar navbar-expand-lg navbar-dark py-lg-4" id="mainNav" style="margin-bottom: 30px; ">
      <div class="container">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-expanded="false">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarResponsive">
          <ul class="navbar-nav mx-auto">
            <li class="nav-item px-lg-3 dropdown">
			        <a class="nav-link text-uppercase text-expanded" href="/">Accueil</a>
            </li>
            <li class="nav-item px-lg-3 dropdown">
                <a class="nav-link dropdown-toggle text-uppercase text-expanded" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Menuiserie</a>
                <div class="dropdown-menu" style="background-color: rgba(47,23,15,.9);">                                    
                  <a class="nav-link text-uppercase text-expanded text-center" href="/products/fenetre">Fenêtre</a>
                  <a class="nav-link text-uppercase text-expanded text-center" href="/products/porte">Porte</a>
                  <a class="nav-link text-uppercase text-expanded text-center" href="/products/agencement">Agencement</a>
                  <a class="nav-link text-uppercase text-expanded text-center" href="/products/menext">Menuiserie EXT.</a>                
                </div>
            </li>            
            <li class="nav-item px-lg-3 dropdown">
			        <a class="nav-link text-uppercase text-expanded" href="/products/nosrealisations">Nos Réalisations</a>
            </li>
            <li class="nav-item px-lg-3 dropdown">
			        <a class="nav-link text-uppercase text-expanded" href="/contact">Nous Contacter</a>
            </li>
            <li v-if="$cookies.get('token')" class="nav-item px-lg-3 dropdown" >
			        <a class="nav-link text-uppercase text-expanded" @click="logout">Déconnexion</a>
            </li>  
          </ul>
        </div>
      </div>
    </nav>
  <!--  <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav>-->

  <router-view/>
  <Footer></Footer>
</template>

<script>  
  import axios from 'axios'
  import Footer from './components/Footer.vue';
  export default {
    components:{
      Footer
    },data(){
      this.islogedin();
      return{};

    },methods:{
        islogedin: function()
        {
          axios.get(this.GBASEURLAPI+"/Login/isLogedin").then((response)=>{
            console.log(response);
          }).catch((response)=>{  
            console.log(response);
          });
        },logout : function()
        {
          axios.get(this.GBASEURLAPI+"/Login/Logout").then((response)=>{
            window.location.href=response.headers.location;
          }).catch((response)=>{
            console.log("logout error");
          });
        }
    }
  }
</script>
<style>
body{
  background-color: #FFFCF7;  
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(47, 23, 15, 0.65))), url( '@/assets/bg.jpg' );    
  margin:0px; 
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  height: 100vh;
  padding-top:60px;
}
#app {
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;  
  text-align: center;  
}

nav {
  padding: 30px;
}

a {
  font-weight: bold;
  color: white;
}

a:hover{
  color: rgba(255, 255, 255, .7);
}

a.router-link-exact-active {
  color: rgb(201, 172, 42);
}

.col-form-label{
        color:White;
    }
</style>
