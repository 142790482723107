<template>
    <div class="container">        
        <div class="about-heading-content">
            <div class="row">
                <div class="col-xl-9 col-lg-10 mx-auto">                    
                    <div class="bg-faded rounded p-4">
                        <h2 class="section-heading mb-4">
                            <span class="section-heading-upper">{{fields.abouttitle}}</span>                            
                        </h2>
                        <span class="mb-0" style="text-align: left;" v-html="fields.abouttext"></span>
                        <button v-if="$cookies.get('token')" type="button" class="btn btn-success m-1"  @click="$router.push('/abouteditor/'+aboutid)" >Modifier</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    props:{
        aboutid:String
    },
    data(){
        this.getAbout();
        return {
            fields:{
                abouttitle:"",
                abouttext:""
            }
        };
    },methods:{
        getAbout:function()
        {
            //this.$route.params.type
            axios.get(this.GBASEURLAPI+"/About/GetAbout/"+this.aboutid).then((response)=>{                
                this.fields = response.data;
                console.log(this.media);
            }).catch((response)=>{
                console.log(response);
            });

        }
    }
}
</script>

<style>

</style>