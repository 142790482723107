import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueCookies from 'vue3-cookies'


import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import styles from "@/assets/business-casual.min.css"

//<link href="@/assets/business-casual.min.css" rel="stylesheet"></link>
//<!--<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css"></link>-->

const app = createApp(App);
app.use(router);
app.use(VueCookies);

//app.config.globalProperties.GBASEURLAPI = "http://localhost:8080/fresardfreres/api";
app.config.globalProperties.GBASEURLAPI = "https://fresardfreres.ch/fresardfreres/api";

app.mount('#app');
